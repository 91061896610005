/** @jsx jsx */

import { jsx } from "@emotion/core"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Tile from "../components/tile"
import TileList from "../components/tile-list"

const SpoonieLiving = ({
  data: {
    allContentfulBlogPost: { edges: blogPosts },
  },
}) => {
  return (
    <Layout>
      <SEO title="Spoonie Living" />

      <TileList>
        {blogPosts.map(({ node: { id, previewImage, slug, previewText, tileColour } }) => {
          const imageUrl = previewImage && previewImage.file.url

          return (
            <Tile key={id}>
              <Link
                css={{ color: "black", textDecoration: "none" }}
                to={`/${slug}`}
              >
                <div
                  css={{
                    background:
                      (imageUrl && `url(${imageUrl})`) ||
                      tileColour ||
                      "#a67fae",
                    backgroundSize: "cover",
                    paddingTop: "100%",
                  }}
                />
                <p css={{ color: "#a07b68", textAlign: "center"}}>{previewText}</p>
              </Link>
            </Tile>
          )
        })}
      </TileList>
    </Layout>
  )
}

export const spoonieLivingPageQuery = graphql`
  query spoonieLivingPageQuery {
    allContentfulBlogPost(
      filter: { categories: { eq: "Spoonie Living" } }
      sort: { fields: postDateTime, order: DESC }
    ) {
      edges {
        node {
          id
          previewImage {
            file {
              url
            }
          }
          slug
          previewText
          tileColour
          title
          updatedAt
        }
      }
    }
  }
`

export default SpoonieLiving
